<script setup lang="ts">
import type { ComponentSuperDiscountProductsProps } from "./ComponentSuperDiscountProducts.props"
import type { OutletProps } from "~/components/ProductBlocks/Outlet.props"
import { mapProducts } from "~/utils/mapProducts"
import { ResultsProps } from "components/ProductTile/Overall/Results.props"
import { VerticalProps } from "components/ProductTile/Overall/Vertical.props"

const props = defineProps<ComponentSuperDiscountProductsProps>()

const { $cl } = useNuxtApp()

const productBlocksOutlet = ref<OutletProps["products"]>([])

const dataComponent = computed<OutletProps>(() => ({
  ...props,
  products: productBlocksOutlet.value
}))

onMounted(async () => {
  const productsPrices = await productsPrice($cl, props.products)

  const productsContenful: VerticalProps[] | ResultsProps[] = mapProducts(
    productsPrices,
    null,
    "CONTENTFUL"
  )
    .filter((product: VerticalProps | ResultsProps) => product.inStock)
    .filter((product: VerticalProps | ResultsProps, index: number) => index < 4)

  const products = await Promise.all(
    productsContenful.map(async (product) => {
      const getTotalReviewsAverages = await $fetch(
        `/api/feedaty/getReviewsAverages/${product.productCode}`,
        {
          method: "GET"
        }
      )

      const ratings = {
        reviews: getTotalReviewsAverages?.Data?.Products?.RatingsCount ?? 0,
        rating: getTotalReviewsAverages?.Data?.Products?.AverageValue ?? 0
      }

      return {
        ...product,
        reviews: ratings.reviews,
        rating: ratings.rating
      }
    })
  )

  productBlocksOutlet.value = products
})
</script>

<template>
  <div class="cms-component-super-discount-products">
    <ProductBlocksOutlet
      v-if="dataComponent && productBlocksOutlet?.length"
      v-bind="dataComponent"
    />
  </div>
</template>
